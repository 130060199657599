body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

/* prestyle */
.text-img{
  font-size: calc(8px + 0.3vw) !important;
}

/* navigation bar */
#logo_container{
  display: flex;
  flex-direction: column;
  width: calc(18em + 1vw);
  line-height: 1.5em;
  margin-top: .8em;
}

.logo{
  width: calc(15em + 1vw);
}

#logo{
  width: calc(12em + 1vw);
  height: auto;
}

/* navigation bar */

.text-divide{
  font-size: calc(13px + 0.3vw) !important;
}


/* typography */
h1{
  font-size: calc(1em + .5vw) !important;
}

h2{
  font-size: calc(.8em + .4vw) !important;
}

h3{
  font-size: calc(.7em + .3vw) !important;
}

h4{
  font-size: calc(.6em + .2vw) !important;
}

h5{
  font-size: calc(.5em + .2vw) !important;
}

h6{
  font-size: calc(.4em + .2vw) !important;
}

p{
  font-size: calc(.5em + .4vw) !important;
}

li{
  font-size: calc(.6em + .4vw) !important;
}

td{
  font-size: calc(.7em + .4vw) !important;
}

th{
  font-size: calc(.5em + .3vw) !important;
}

small{
  font-size: calc(.5em + .2vw) !important;
}
/* typography */