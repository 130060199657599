.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80vh;
}

@media screen and (max-width: 767px) {
  .cta {
    display: flex;
    flex-direction: column;
  }

  .cta_img {
    border-radius: 5px;
  }

  .center-content {
    flex-direction: row;
  }
}

/* carousel */
.carousel-item {
  position: relative;
}

.carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.151); /* Semi-transparent black overlay */
  text-align: center;
}

.carousel-caption h3 {
  margin: 0;
  font-size: calc(1em + .5vw);
  color: white;
  font-weight: 800;
  text-transform: uppercase;
}

/* Default styles for larger screens */
.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Adjust for small screens (e.g., mobile phones) */
@media (max-width: 767.98px) {
  .carousel-item {
    height: 50vh;
  }

  .carousel-image {
    height: 100%;
    object-fit: cover;
  }
}
/* carousel */
