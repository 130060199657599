/* Base font size for body text */
body {
  font-size: calc(14px + 0.5vw);
  line-height: 1.5;
}

h1 {
  font-size: calc(30px + 1vw);
}

h2 {
  font-size: calc(28px + 0.8vw);
}

h3 {
  font-size: calc(26px + 0.6vw);
}

h4 {
  font-size: calc(24px + 0.5vw);
}

h5 {
  font-size: calc(22px + 0.4vw);
}

h6 {
  font-size: calc(20px + 0.3vw);
}

.small-text {
  font-size: calc(18px + 0.2vw);
}

/* Responsive adjustments for very small screens */
@media (max-width: 600px) {
  body {
    font-size: calc(14px + 1vw);
  }

  h1 {
    font-size: calc(28px + 1.2vw);
  }

  h2 {
    font-size: calc(26px + 1vw);
  }

  h3 {
    font-size: calc(24px + 0.8vw);
  }

  h4 {
    font-size: calc(22px + 0.6vw);
  }

  h5 {
    font-size: calc(20px + 0.5vw);
  }

  h6 {
    font-size: calc(18px + 0.4vw);
  }

  .small-text {
    font-size: calc(16px + 0.3vw);
  }
}

/* Responsive adjustments for large screens */
@media (min-width: 1025px) {
  body {
    font-size: 18px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 22px;
  }

  .small-text {
    font-size: 16px;
  }
}

/* submit button */
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #0f1923;
  cursor: pointer;
  position: relative;
  padding: 8px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.15s ease;
}

.button::before,
.button::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid #7d8082;
  transition: all 0.15s ease;
}

.button::before {
  top: 0;
  border-bottom-width: 0;
}

.button::after {
  bottom: 0;
  border-top-width: 0;
}

.button:active,
.button:focus {
  outline: none;
}

.button:active::before,
.button:active::after {
  right: 3px;
  left: 3px;
}

.button:active::before {
  top: 3px;
}

.button:active::after {
  bottom: 3px;
}

.button_lg {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #fff;
  background-color: #0f1923;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.button_lg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #0f1923;
}

.button_lg::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: #0f1923;
  transition: all 0.2s ease;
}

.button_sl {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -8px;
  width: 0;
  background-color: goldenrod;
  transform: skew(-15deg);
  transition: all 0.2s ease;
}

.button_text {
  position: relative;
}

.button:hover {
  color: #0f1923;
}

.button:hover .button_sl {
  width: calc(100% + 15px);
}

.button:hover .button_lg::after {
  background-color: #fff;
}

/* submit button */
